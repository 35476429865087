import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_MEDIA_THUMB,
  ADD_SUBTITLE,
  COMPLETE_UPLOAD,
  CREATE_MEDIA,
  DELETE_MEDIA,
  EDIT_MEDIA_SOURCE,
  EDIT_SUBTITLE,
  INIT_UPLOAD,
  REMOVE_MEDIA_THUMB,
  UPDATE_MEDIA
} from "../mutations/media";
import {
  GET_ENCODING_MEDIAS,
  GET_MEDIA_CREATION_DATE,
  GET_MEDIA_DATA,
  GET_MEDIA_DURATION,
  GET_MEDIA_FILES_INFOS,
  GET_MEDIA_INFOS,
  GET_MEDIA_LIVE,
  GET_MEDIA_PROGRESS,
  GET_MEDIA_THUMBNAILS,
  GET_PLAYLIST_CREATION_DATE
} from "../queries/media";


/***************/
/*** Queries ***/
/***************/
export const GetMedia = (mediaId, skip = false) => {
  const { data, loading, error, refetch } = useQuery(GET_MEDIA_DATA, {
    variables: {
      mediaId,
    },
    skip,
  });
  
  const availableData = data && data.media;

  return {
    availableData,
    loading,
    error,
    refetch,
  };
};

export const GetMediaInfos = (mediaId, skip) => {
  const { data, loading, error, refetch } = useQuery(GET_MEDIA_INFOS, {
    variables: {
      mediaId,
    },
    skip,
  });
  
  const availableData = data && data.media;

  return {
    mediaInfos: availableData,
    loading,
    error,
    refetch,
  };
};

export const GetMediaDuration = (mediaId, skip) => {
  const { loading, error, data } = useQuery(GET_MEDIA_DURATION, {
    variables: {
      mediaId,
    },
    skip,
  });

  
  return {
    duration: parseInt(data?.media?.duration),
    loading,
    error,
  };
};

export const GetMediaCreationDate = (mediaId, skip) => {
  const { loading, error, data } = useQuery(GET_MEDIA_CREATION_DATE, {
    variables: {
      mediaId,
    },
    skip,
  });

  
  const createdAt = data?.media?.createdAt;

  return {
    createdAt,
    loading,
    error,
  };
};

export const GetPlaylistCreationDate = (playlistId, skip) => {
  const { loading, error, data } = useQuery(GET_PLAYLIST_CREATION_DATE, {
    variables: {
      playlistId: playlistId,
    },
    skip,
  });

  const createdAt = data?.playlist?.createdAt;

  return {
    createdAt,
    loading,
    error,
  };
};

export const GetMediaFilesInfos = (mediaId, skip) => {
  const { loading, error, data, refetch } = useQuery(GET_MEDIA_FILES_INFOS, {
    variables: {
      mediaId,
    },
    skip,
  });

  
  const media = data?.media;

  return {
    media,
    loading,
    error,
    refetch,
  };
};

export const GetMediaThumbnails = (mediaId) => {
  const { loading, error, data } = useQuery(GET_MEDIA_THUMBNAILS, {
    variables: {
      mediaId,
    },
  });

  const availableData = data && data.media;

  return {
    loading,
    data: availableData,
    error,
  };
};

export const GetEncodingMedias = () => {
  const { loading, error, data, startPolling, stopPolling } = useQuery(
    GET_ENCODING_MEDIAS
  );

  const availableData = data && data.getEncodingMedias;

  return {
    loading,
    medias: availableData,
    error,
    startPolling,
    stopPolling,
  };
};

export const GetMediaLive = (mediaId, skip) => {
  const { loading, error, data } = useQuery(GET_MEDIA_LIVE, {
    variables: {
      mediaId,
    },
    skip
  });

  const liveID = data?.media?.live?.id;

  return {
    loading,
    hasLive: !!liveID,
    liveToVodId: liveID,
    startedDate: data?.media?.live?.startedDate,
    endedDate: data?.media?.live?.endedDate,
    error,
  };
};

/*****************/
/*** Mutations ***/
/*****************/

export const useUpdateMedia = () => {
  const [updateMedia] = useMutation(UPDATE_MEDIA);
  return async (variables) => {
    try {
      const { data } = await updateMedia({
        variables,
      });
      return {
        data,
      };
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useAddMediaThumb = () => {
  const [addMediaThumb] = useMutation(ADD_MEDIA_THUMB);
  return async (variables) => {
    try {
      const { data } = await addMediaThumb({
        variables,
      });
      return {
        data,
      };
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useRemoveMediaThumb = () => {
  const [removeMediaThumb, { loading }] = useMutation(REMOVE_MEDIA_THUMB, );
  return {
    loading,
    removeMediaThumb: async (variables) => {
      try {
        const { data } = await removeMediaThumb(variables);
        return {
          data,
        };
      } catch (error) {
        return {
          error,
        };
      }
    },
  };
};

export const useDeleteMedia = () => {
  const [deleteMedia] = useMutation(DELETE_MEDIA);
  return async ({ variables }) => {
    try {
      const { data } = await deleteMedia({
        variables,
      });
      return data;
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useCreateMedia = () => {
  const [createMedia] = useMutation(CREATE_MEDIA);
  return async (variables) => {
    try {
      const { data } = await createMedia({
        variables,
      });
      return {
        data,
      };
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useEditMediaSource = () => {
  const [editMediaSource] = useMutation(EDIT_MEDIA_SOURCE);
  return async (variables) => {
    try {
      const { data } = await editMediaSource({
        variables,
      });
      return {
        data,
      };
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useEditSubtitle = () => {
  const [editSubtitle, {
    loading
  }] = useMutation(EDIT_SUBTITLE);
  return {
    editSubtitleLoading: loading,
    editSubtitle: async (variables) => {
      try {
        const { data } = await editSubtitle({
          variables,
        });
        return {
          data,
        };
      } catch (error) {
        return {
          error,
        };
      }
    },
  };
};

export const useCreateSubtitle = () => {
  const [createSubtitle] = useMutation(ADD_SUBTITLE);
  return async (variables) => {
    try {
      const { data } = await createSubtitle(variables);
      return {
        data,
      };
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const GetMediaProgress = (mediaId, skip, isVersioning) => {
  const { data, loading, subscribeToMore, refetch } = useQuery(
    GET_MEDIA_PROGRESS,
    {
      variables: { mediaId },
      skip,
    }
  );

  return {
    encodingProgress: isVersioning
      ? data?.media?.versioningProgress
      : data?.media?.encodingProgress.find(
        (progress) =>
          progress.encodingVersion === data?.media?.encodingVersion
      ),
    isLegacy: data?.media?.encodingVersion === "v0",
    loading,
    refetch,
    subscribeToMore,
  };
};

export const useInitUpload = () => {
  const [initUpload] = useMutation(INIT_UPLOAD);
  return async (variables) => {
    try {
      const { data } = await initUpload(variables);
      return {
        data,
      };
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useCompleteUpload = () => {
  const [completeUpload] = useMutation(COMPLETE_UPLOAD);
  return async (variables) => {
    try {
      const { data } = await completeUpload(variables);
      return {
        data,
      };
    } catch (error) {
      return {
        error,
      };
    }
  };
};
