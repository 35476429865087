import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies
export const CREATE_WEB_TV = gql`
  mutation CreateWebTV {
    createWebTV {
      id
      publisher {
        id
      }
    }
  }
`;
export const UPDATE_WEB_TV = gql`
  mutation UpdateWebTV(
    $id: ID!
    $domain: String
    $isPublished: Boolean
    $identifier: String
    $rollOption: WebTVRollOption
    $note: String
    $showLinksInHeader: Boolean
    $extraFooter: Json
    $gtmId: String
    $ga4Id: String
    $matomoCloudId: String
    $matomoCloudUrl: String
    $matomoCloudJsPath: String
    $matomoSelfHostedId: String
    $matomoSelfHostedUrl: String
  ) {
    updateWebTV(
      id: $id
      isPublished: $isPublished
      domain: $domain
      identifier: $identifier
      rollOption: $rollOption
      note: $note
      showLinksInHeader: $showLinksInHeader
      extraFooter: $extraFooter
      gtmId: $gtmId
      ga4Id: $ga4Id
      matomoCloudId: $matomoCloudId
      matomoCloudUrl: $matomoCloudUrl
      matomoCloudJsPath: $matomoCloudJsPath
      matomoSelfHostedId: $matomoSelfHostedId
      matomoSelfHostedUrl: $matomoSelfHostedUrl
    ) {
      id
      isPublished
      identifier
      domain
      rollOption
      note
      gtmId
      ga4Id
      matomoCloudId
      matomoCloudUrl
      matomoCloudJsPath
      matomoSelfHostedId
      matomoSelfHostedUrl
      template {
        id
        showLinksInHeader
        extraFooter
      }
    }
  }
`;

export const UPDATE_WEB_TV_CONTACT = gql`
  # WebTVPresentation
  mutation updateWebTVContactPage(
    $id: ID!
    $webTVId: ID!
    $phone: String
    $adress: String
    $city: String
    $postalCode: String
    $country: String
    $websiteUrl: String
    $email: String
  ) {
    updateWebTVContactPage(
      id: $id
      webTVId: $webTVId
      phone: $phone
      address: $adress
      city: $city
      postalCode: $postalCode
      country: $country
      websiteUrl: $websiteUrl
      email: $email
    ) {
      id
      phone
      address
      city
      postalCode
      country
      websiteUrl
      email
    }
  }
`;

export const DELETE_WEB_TV = gql`
  mutation deleteWebTV($id: ID!) {
    deleteWebTV(id: $id) {
      id
    }
  }
`;

export const CREATE_WEB_TV_LINK = gql`
  mutation createWebTVLink($webTVId: ID!, $label: String!, $url: String!) {
    createWebTVLink(url: $url, label: $label, webTVId: $webTVId) {
      id
      url
      label
      webTV {
        id
      }
    }
  }
`;

export const UPDATE_WEB_TV_LINK = gql`
  mutation updateWebTVLink(
    $id: ID!
    $webTVId: ID!
    $label: String!
    $url: String!
  ) {
    updateWebTVLink(url: $url, webTVId: $webTVId, label: $label, id: $id) {
      id
      url
      label
    }
  }
`;


export const REORDER_WEB_TV_LINKS = gql`
  mutation reorderWebTVLinks($oldIndex: Int!, $newIndex: Int!, $webTVId: ID!) {
    reorderWebTVLinks(oldIndex: $oldIndex, newIndex: $newIndex, webTVId: $webTVId) {
      id
      order
    }
  }
`;

export const DELETE_WEB_TV_LINK = gql`
  mutation deleteWebTVLink($id: ID!, $webTVId: ID!) {
    deleteWebTVLink(id: $id, webTVId: $webTVId) {
      id
    }
  }
`;

export const UPDATE_WEB_TV_DOMAIN = gql`
  mutation UpdateWebTVDomain($id: ID!, $name: String, $environment: String!) {
    updateWebTVDomain(id: $id, name: $name, environment: $environment) {
      id
      domain
    }
  }
`;
