import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_CONTENT_TO_PLAYLIST,
  CREATE_PLAYLIST_WITH_CONTENT,
  DELETE_PLAYLIST,
  REMOVE_CONTENT_FROM_PLAYLIST,
  REORDER_PLAYLIST_CONTENT,
  UPDATE_PLAYLIST_V2
} from "../mutations/playlist";
import {
  GET_PLAYLIST_CONTENT,
  GET_PLAYLIST_DATA,
  GET_PLAYLIST_INFOS,
} from "../queries/playlist";

/*****************/
/*** Queries ***/
/*****************/

export const GetPlaylistContent = (id, skip) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(
    GET_PLAYLIST_CONTENT,
    {
      variables: {
        id,
        cursor: null,
        limit: 40,
      },
      skip,
      notifyOnNetworkStatusChange: true,
    }
  );

  return {
    playlist: data?.playlist,
    playlistContent: data?.playlist?.playlistContents?.playlistContent,
    pageInfo: data?.playlist?.playlistContents?.pageInfo,
    totalCount: data?.playlist?.playlistContents?.totalCount,
    loading,
    error,
    refetch,
    fetchMore,
  };
};

export const GetPlaylistData = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_PLAYLIST_DATA, {
    variables: {
      id,
    },
    // fetchPolicy: "no-cache",
  });

  const availableData = data && data.playlist;

  return {
    playlist: availableData,
    loading,
    error,
    refetch,
  };
};

export const GetPlaylistInfos = (playlistId, skip) => {
  const { data, loading } = useQuery(GET_PLAYLIST_INFOS, {
    variables: { playlistId },
    skip,
  });

  const availableData = data && data.playlist;

  return {
    loading,
    playlistInfos: availableData,
  };
};

/*****************/
/*** Mutations ***/
/*****************/
export const useUpdatePlaylistV2 = () => {
  const [updatePlaylistV2, { loading }] = useMutation(UPDATE_PLAYLIST_V2);
  return {
    updatePlaylistV2: (variables) => updatePlaylistV2({ variables }),
    updatePlaylistV2Loading: loading,
  };
};

export const useCreatePlaylistWithContent = () => {
  const [createPlaylistWithContent, { loading }] = useMutation(
    CREATE_PLAYLIST_WITH_CONTENT
  );
  return {
    createPlaylistWithContent: (variables) =>
      createPlaylistWithContent(variables),
    createPlaylistWithContentLoading: loading,
  };
};

export const useAddContentToPlaylist = (playlistId) => {
  const [addContentToPlaylist, { loading }] = useMutation(
    ADD_CONTENT_TO_PLAYLIST,
    {
      refetchQueries: [
        {
          query: GET_PLAYLIST_CONTENT,
          variables: {
            id: playlistId,
            cursor: null,
            limit: 20,
          },
        },
      ],
    }
  );
  return {
    addContentToPlaylist: (variables) => addContentToPlaylist({ variables }),
    addContentToPlaylistLoading: loading,
  };
};

export const useReorderContentPlaylist = (playlistId) => {
  const [reorderContentPlaylist, { loading }] = useMutation(
    REORDER_PLAYLIST_CONTENT
  );
  return {
    reorderContentPlaylist: (variables) =>
      reorderContentPlaylist({ variables }),
    reorderContentPlaylistLoading: loading,
  };
};

export const useRemoveContentFromPlaylist = () => {
  const [removeContentFromPlaylist, { loading }] = useMutation(
    REMOVE_CONTENT_FROM_PLAYLIST
  );
  return {
    removeContentFromPlaylist: (variables) =>
      removeContentFromPlaylist({ variables }),
    removeContentFromPlaylistLoading: loading,
  };
};

export const useDeletePlaylist = () => {
  const [deletePlaylist] = useMutation(DELETE_PLAYLIST);
  return async (id) => {
    try {
      await deletePlaylist(id);
    } catch (error) {
      return {
        error,
      };
    }
  };
};
