import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

import {
  GRAPHQL_BACKOFFICE_API,
  GRAPHQL_BACKOFFICE_WS_API,
} from "../constants";
import { callbackErrorLink } from "./_utils";

const withToken = setContext((_, { headers }) => {
  let token;
  try {
    token = localStorage && localStorage.getItem("accessToken");
  } catch (e) {}
  return {
    headers: {
      ...headers,
      "x-graphql-client-name": "backoffice",
      "x-graphql-client-version": "v1.0.0",
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: GRAPHQL_BACKOFFICE_API,
  credentials: "include",
  // process.env.REACT_APP_ENVIRONMENT !== "local" ? "same-origin" : "include",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: GRAPHQL_BACKOFFICE_WS_API,
    connectionParams: {
      token:
        localStorage && localStorage.getItem("accessToken"),
    },
  })
);

const httpLinkWithAuth = withToken.concat(httpLink);
const WsOrHttpLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLinkWithAuth
);

const cache = new InMemoryCache({
  dataIdFromObject: (o) => o.id,
  typePolicies: {
    Message: {
      fields: {
        answers: {
          merge(existing = [], incoming = []) {
            if (!incoming) return existing;
            const merged = [...existing];
            incoming.forEach((webTVContent) => {
              const index = existing.findIndex(
                (existing) => existing.__ref === webTVContent.__ref
              );
              if (index > -1) {
                merged[index] = webTVContent;
              } else {
                merged.push(webTVContent);
              }
            });
            return merged;
          },
        },
      },
    },
    Section: {
      fields: {
        sectionContents: {
          keyArgs: false,
          merge(existing = { webTVContent: [], pageInfo: {} }, incoming, { args }) {
            // If the query is starting fresh (cursor is null), reset the cache
            if (args?.cursor === null || incoming.webTVContent.length === 0) {
              return {
                ...incoming,
                webTVContent: [...incoming.webTVContent],  // Reset the webTVContent
                pageInfo: incoming.pageInfo || {},         // Update pageInfo
              };
            }
    
            // Otherwise, perform the usual merging of content
            const mergedWebTVContent = [...existing.webTVContent];
            incoming.webTVContent.forEach((webTVContent) => {
              const index = existing.webTVContent.findIndex(
                (existing) => existing.__ref === webTVContent.__ref
              );
              if (index > -1) {
                mergedWebTVContent[index] = webTVContent;
              } else {
                mergedWebTVContent.push(webTVContent);
              }
            });
    
            // Update pageInfo
            const mergedPageInfo = {
              ...existing.pageInfo,
              ...incoming.pageInfo,
            };
    
            return {
              ...incoming,
              webTVContent: mergedWebTVContent,
              pageInfo: mergedPageInfo,
            };
          },
        },
      },
    },
    Playlist: {
      fields: {
        playlistContents: {
          keyArgs: false,
          merge(existing = { playlistContent: [], pageInfo: {} }, incoming, { args }) {
            // If the query is starting fresh (cursor is null) or the incoming data is empty, reset the cache
            if (args?.cursor === null || incoming.playlistContent.length === 0) {
              return {
                ...incoming,
                playlistContent: [...incoming.playlistContent],  // Reset the playlistContent
                pageInfo: incoming.pageInfo || {},              // Update pageInfo
              };
            }
    
            // Otherwise, perform the usual merging of content
            const mergedPlaylistContent = [...existing.playlistContent];
            incoming.playlistContent.forEach((playlistContent) => {
              const index = existing.playlistContent.findIndex(
                (existing) => existing.__ref === playlistContent.__ref
              );
              if (index > -1) {
                mergedPlaylistContent[index] = playlistContent;
              } else {
                mergedPlaylistContent.push(playlistContent);
              }
            });
    
            // Update pageInfo
            const mergedPageInfo = {
              ...existing.pageInfo,
              ...incoming.pageInfo,
            };
    
            return {
              ...incoming,
              playlistContent: mergedPlaylistContent,
              pageInfo: mergedPageInfo,
            };
          },
        },
      },
    },
    Query: {
      fields: {
        messageFeedBackOffice: {
          keyArgs: false,
          merge(existing, incoming, { args: { cursor } }) {
            if (!incoming?.messages?.length) return existing;
            if (!existing?.messages?.length) return incoming;

            //const fromSubscription = cursor ? false : true;
            const merged = [...existing.messages];
            //const newMessageFromSubscription = [];

            incoming.messages.forEach((webTVContent) => {
              const index = existing.messages.findIndex(
                (existing) => existing.__ref === webTVContent.__ref
              );
              if (index > -1) {
                merged[index] = webTVContent;
              } else {
                merged.push(webTVContent);
                // if (fromSubscription) {
                //   newMessageFromSubscription.push(webTVContent);
                // } else {
                //   merged.push(webTVContent);
                // }
              }
            });

            return {
              ...incoming,
              messages: merged,
            };
          },
        },
      },
    },
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) =>
    callbackErrorLink({ graphQLErrors, networkError, operation, forward })
);

const client = new ApolloClient({
  link: ApolloLink.from([withToken, errorLink, WsOrHttpLink]),
  cache,
  connectToDevTools: true,
  queryDeduplication: true,
  name: `Streamfizz ${process.env.REACT_APP_ENVIRONMENT}`,
});

export default client;
